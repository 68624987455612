.insurance-info {
    .insurance-info-wrapper {
        flex-wrap: wrap;
        padding: 16px;
        border-radius: 8px;
        box-shadow: 0 2px 10px 0 rgba(74, 144, 226, 0.3);
        padding-bottom: 24px;
    }
    .price-time {
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-size: 14px;
    }
    .title-insurance {
        font-size: 16px;
        font-weight: bold;
        display: flex;
        margin-top: 22px;
        justify-content: center;
        width: 100%;
        text-align: center;
    }
    .maximum-limit {
        border-radius: 4px;
        margin-top: 20px;
        background-color: #f2f7ff;
        padding: 12px 8px;
        width: 100%;
        p {
            margin: 2px;
            font-size: 12px;
            &:first-child {
                margin-bottom: 4px;
                font-size: 14px;
                font-weight: bold;
            }
        }
    }
    .form_row {
        margin-top: 10px;
        width: 100%;
    }
    .form-radio {
        display: flex;
        align-items: center;
        gap: 12px;
        flex: auto;
        .input-radio {
            display: flex;
            align-items: center;
            gap: 4px;
            margin-top: 2px;
            input {
                appearance: none;
                width: 13px;
                height: 13px;
                border: 1px solid #aaa;
                border-radius: 50%;
                position: relative;
            }
            input:checked {
                &:default::after {
                    color: #007aff;
                    border: 1px solid #007aff;
                    background-color: #007aff;
                }
                &:disabled::after {
                    color: grey;
                    border: 1px solid grey;
                    background-color: grey;
                }
                &::after {
                    content: "";
                    position: absolute;
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            label {
                font-size: 14px;
                margin-bottom: 3px;
            }
            input:disabled+label {
                color: grey;
            }
        }
    }
}